<template>
  <div>
    <b-card>
      <b-input-group class="mb-2">
        <b-input-group-prepend>
          <b-button variant="primary" @click="list()" :disabled="busy.list">
            <i class="fa fa-search"></i> 검색
            <b-spinner class="mr-1" small v-if="busy.list"></b-spinner>
          </b-button>
        </b-input-group-prepend>
        <b-form-input type="text" placeholder="검색어를 입력해주세요" v-model="form.list.search" @keypress.enter.prevent.stop="list()" v-focus></b-form-input>
      </b-input-group>
      <color-preset class="mb-2" v-model="form.list.colors"></color-preset>
      <b-button @click="list()" class="mt-1 mr-1" variant="primary">검색</b-button>
    </b-card>

    <div class="mt-3 mb-2">
      <b-button @click="showDownModal()" class="mr-1" variant="success">매핑 Xlsx Down</b-button>
      <b-button @click="() => {$refs.xlsx.value = null;$refs.xlsx.click()}" class="mr-1" variant="outline-success">매핑 Xlsx Upload</b-button>
      <b-button @click="removeSelected()" class="mr-1" variant="danger" :disabled="busy.remove">선택삭제<b-spinner class="mr-1" small v-if="busy.remove"></b-spinner></b-button>
      <input type="file" ref="xlsx" style="display: none" @change="handleXlsx">
    </div>

    <c-table id="colorMapping" :table-data="items.list" :fields="fields.list" :perPage.sync="perPage"
             :isBusy="busy.list" :getMoreBusy="busy.listmore" :hasMore="hasMore.list" @btn-clicked="btnAction"
             :caption="items.list.length + ' 개 매핑데이터'" @get-more="list(true)">
    </c-table>

    <b-modal title="컬러 매핑 다운로드" size="xl" v-model="modal.down">
      <color-preset class="mb-2" v-model="form.down.colors"></color-preset>
      <b-button @click="downXlsx()" class="mt-1 mr-1" variant="success">Down Xlsx</b-button>

      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button variant="primary" @click="cancel()">
          닫기
        </b-button>
      </template>
    </b-modal>

    <iframe ref="file_frame" name="file_frame" style="width:1px;height:1px;visibility:hidden"></iframe>
    <form :action="$api.getHost() + '/meta/color/mappingXlsx'" ref="file_form" method="POST" target="file_frame" style="width:1px;height:1px;visibility:hidden">
      <input ref="json_data" type="hidden" name="j" />
    </form>
  </div>
</template>
<script>
import {readXlsx} from '@/shared/impexp'

export default {
  name: 'ColorMapping',
  title: '색 분류',
  data() {
    return {
      defaultForm: {
        list: {
          search: '',
          colors: [],
        }
      },
      form: {
        list: {
          search: '',
          colors: [],
        },
        down: {
          colors: [],
        }
      },
      lastBody: {list: {}},
      item: {},
      items: {list: []},
      busy: {list: false, listmore: false, remove: false},
      hasMore: {list: false},
      ac: {list: null}, // abortController
      modal: {down: false},
      perPage: 20,

      colorMap: {},
      fields: {
        list: [
          {key: 'selected', class: 'w-65px'},
          {key: 'html1', label: ''},
          {key: 'color', label: '수집된 색'},
          {key: 'matched_color', label: '매핑할 색'},
        ]
      },
      options: {
        colors: [
        ],
      }
    };
  },
  async created() {
    this.$utils.getStatus(this.$options.name, this, 'perPage');
    let meta = await this.$api.getMeta('color');
    if (!meta) return;

    this.options.colors = meta.color.sort((a, b) => a.color.localeCompare(b.color));
    this.options.colors.forEach(c => {
      c.label = `${c.color} (${c.hexcolor})`;
      this.colorMap[c.color] = c;
    });
    this.list();
  },
  methods: {
    async list(more) {
      const form = this.form.list;
      const colors = form.colors.map(e => e.color);
      await this.$api.postTable(this, '/meta/color/mapping', {...form, colors}, {more, fnAssign: this.assignTableData});
    },
    assignTableData(e) {
      const hex = e.matched_color === 'multi' ? `linear-gradient(135deg, red,orange,yellow,green,blue,indigo,violet)` : this.colorMap[e.matched_color].hexcolor;
      e.html1 = `<div style="width:21px;height:21px;background:${hex}"></div>`;
    },
    btnAction (row, event) {
      if (event === 'remove') {
        this.remove(row);
      }
    },
    showDownModal() {
      this.form.down.colors.splice(0, this.form.down.colors.length);
      this.modal.down = true;
    },
    async downXlsx() {
      this.$refs.json_data.value = JSON.stringify({colors: this.form.down.colors.map(e => e.color)});
      this.$refs.file_form.submit();
    },
    async removeSelected() {
      const selected = this.items.list.filter(e => e.selected);
      if (selected.length === 0) return alert('삭제할 컬러 매핑을 선택해 주시기 바랍니다.');
      this.busy.remove = true;
      if (!confirm(`${selected.length} 개의 컬러 매핑을 정말로 삭제하시겠습니까?`)) return;
      this.busy.remove = false;
      const j = await this.$api.postJson('/meta/color/removeMappingMany', {colors: selected.map(e => e.color)});
      if (j) {
        this.list();
      }
    },
    async remove(row) {
      if (!confirm(`${row.item.color} 컬러의 매핑을 삭제하시겠습니까?`)) return;

      this.busy.remove = true;
      let j = await this.$api.postJson('/meta/color/removeMapping', {color: row.item.color});
      this.busy.remove = false;
      if (j) {
        this.list();
      }
    },
    async handleXlsx(event) {
      const file = (event.dataTransfer || event.target).files[0];
      if (!file || !file.name.endsWith('xlsx') && !file.name.endsWith('xls')) return this.$utils.alert('xlsx 파일을 업로드해주세요');
      const {rows} = await readXlsx(file);
      const j = await this.$api.postJson('/meta/color/updateMapping', {rows});
      if (j) {
        this.$alertTop(`업로드 되었습니다. ${j.cnt} 개 데이터가 수정되었습니다.`);
        this.list();
      }
    },
  },
}
</script>
